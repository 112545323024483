import request from '@/utils/request'

export default class ChuFangApi {

  // 获取运动频率
  public async getAllFrequency(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: '/keep-fit/motion-frequency/all',
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  // 自我评估新增
  public async addMemberShipAssessment(dataForm: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: '/keep-fit/memberShipAssessments',
        method: 'post',
        data: dataForm
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  // 获取自我最后一次评估数据
  public async getLastAssessment(isFinish: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: '/keep-fit/memberShipAssessments/last',
        method: 'get',
        params: {isFinish}
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  // 保存基础信息
  public async saveBasicInfo(id: any, dataForm: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `/keep-fit/memberShipAssessments/${id}/basic`,
        method: 'post',
        data: dataForm
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  // 保存仪器测量信息
  public async saveCeliang(id: any, dataForm: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `/keep-fit/memberShipAssessments/${id}/measure`,
        method: 'post',
        data: dataForm
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }
}
