





















































import {Component, Vue} from 'vue-property-decorator'
import ChuFangApi from '@/api/chufang'

@Component({})
export default class ApparatusMeasure extends Vue {
  private api = new ChuFangApi()

  private dataForm = {
    bodyFatRatio: undefined,
    segmentalMuscle: undefined,
    segmentalFat: undefined
  }

  private showMuscle = false
  private muscle = ''

  private showAxunge = false
  private fat = ''

  private get aid() {
    return this.$route.params.aid
  }

  private actions = [
    {name: '均达标', id: 10},
    {name: '上肢未达标', id: 11},
    {name: '下肢未达标', id: 12},
    {name: '躯干未达标', id: 13},
    {name: '上肢&躯干未达标', id: 14},
    {name: '下肢&躯干未达标', id: 15},
    {name: '上肢&下肢未达标', id: 16}
  ]

  private async handleFinish() {
    if (
      this.dataForm.bodyFatRatio === undefined ||
      this.dataForm.segmentalMuscle === undefined ||
      this.dataForm.segmentalFat === undefined
    ) {
      this.$notify({type: 'warning', message: '不能为空'})
      return false
    }

    await this.api.saveCeliang(this.aid, this.dataForm)

    await this.$router.replace('/personal-tailor/body-analysis')
  }

  private onAxungeSelect(item: any) {
    // 默认情况下点击选项时不会自动收起
    // 可以通过 close-on-click-action 属性开启自动收起
    this.showAxunge = false
    this.dataForm.segmentalFat = item.id
    this.fat = item.name
    this.$toast(item.name)
  }

  private onSelect(item: any) {
    // 默认情况下点击选项时不会自动收起
    // 可以通过 close-on-click-action 属性开启自动收起
    this.showMuscle = false
    this.dataForm.segmentalMuscle = item.id
    this.muscle = item.name
    this.$toast(item.name)
  }

}
